@import "src/scss/global";

.Mitigation {
  margin-top: var(--gap);
  line-height: $line-height-body-tighter;

  &:not(.Mitigation-editing) {
    display: grid;
    align-items: center;
    grid-template-columns: 1fr auto;
    gap: 0 var(--gap);
    padding: var(--gap) var(--item-gutter);
  }
}
.MitigationText {
  font-weight: $fw-medium;
  word-break: break-word;
  flex: 1;
}
.MitigationEdit {
  grid-column: 2;
  grid-row: 1;
}
.Empty {
  color: $color-disabled;
}

.MitigationConsequences {
  font-size: var(--font-size-small);
  grid-column: 1 / span 2;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(#{rem(200)}, 1fr));
  gap: 0 calc(var(--gutter) / 2);

  h5 {
    margin-top: $text-relative-space * 2;
  }

  p {
    font-size: inherit;
    line-height: $line-height-body-tighter;
  }
}

.EditForm {
  align-items: flex-start;
  display: grid;
  gap: calc(var(--gap) * 2) var(--gap);

  > label {
    margin-top: 0;
  }

  @include min-width($bp-medium) {
    grid-template-columns: 1fr 1fr;
  }
}
.EditFormText {
  margin-top: 0;
  grid-column: 1 / -1;
}
.EditFormButtons {
  display: flex;
  gap: var(--gap);
  grid-column: 1 / -1;
}
.EditFormSave {
  width: $editing-save-cancel-form-button-size;
  padding-left: 0;
  padding-right: 0;
}

.CreationForm {
  --creation-form-button-min-width: #{$option-creation-form-button-size};

  margin-top: var(--gap);

  .Mitigation + & {
    margin-top: calc(var(--gap) * 2);
  }
}
