@import "src/scss/global";

$bp-footer-toggle-button: 620;
$bp-header-unstick: 520;

$toggle-button-width: calc(var(--button-size-medium) * 13);

.Header {
  // On really small screens the max-length of an option can stretch to 4–5
  // lines so it is best to just not stick any of the headings at this size
  // to ensure that it is still usable. This stacks with the standard "de-sticking"
  // in Card on short viewports
  @include max-width($bp-header-unstick) {
    position: relative;
  }

  h2 {
    @include min-width($bp-footer-toggle-button) {
      max-width: none;

      span {
        max-width: $heading-h2-max-width;
        display: block;
        // This forces a misalignment to avoid the title lining up perfectly with
        // the toggle button as this looks like a mistaken rendering
        width: calc(100% - var(--gutter));
      }

      // This adds a floated shim that sits in the position of the button to
      // force the text to wrap around it even though it's positioned absolutely
      // It only needs to be half the line-height to force the wrap, so this
      // gives the browser a little bit of wriggle room to render the shape
      // without it needing to be exactly $line-height-heading * 1em
      &::before {
        content: "";
        width: calc(#{$toggle-button-width} + (var(--gutter) / 2));
        height: $line-height-heading * 0.5em;
        display: block;
        float: right;
      }
    }
  }

  + hr {
    display: none;
  }
}
.Empty {
  color: $color-disabled;
}

.ScoreBar {
  background: $color-300;
  display: block;
  border-radius: rem(50);
  height: var(--gap);
  flex: 1;
  position: absolute;
  bottom: calc(var(--gap) / -2);
  left: var(--card-gutter);
  right: var(--card-gutter);
  padding: 0 var(--gap);
  overflow: hidden;

  &::before {
    border-radius: inherit;
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: $color-positive;
    transform: translateX(calc((100 - var(--percent)) * -1%));
  }
}

.Mitigation {
  margin-top: $text-relative-space;
  font-size: var(--font-size-paragraph);

  &-first {
    margin-top: var(--gap);
  }
}
.MitigationConsequences {
  margin-bottom: var(--gap);

  .Mitigation:last-of-type & {
    margin-bottom: 0;
  }
}

.Toggle {
  &-header {
    position: absolute;
    top: calc(var(--gutter) / 2);
    right: var(--card-gutter);
    width: $toggle-button-width;

    @include max-width($bp-footer-toggle-button) {
      display: none;
    }
  }
  &-footer {
    margin-top: var(--gutter);

    @include min-width($bp-footer-toggle-button) {
      display: none;
    }
  }
}
