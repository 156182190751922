@use "sass:color";
@use "sass:string";
@use "sass:math";

@import "src/scss/reset";
@import "src/scss/global";

@font-face {
  font-family: $font-heading;
  font-style: normal;
  font-weight: $fw-heading;
  font-display: swap;
  src: url("./fonts/RRI-Heading-#{$fw-heading}.woff2") format("woff2");
}

$font-weights: $fw-regular, $fw-medium, $fw-bold;

@each $font-weight in $font-weights {
  @font-face {
    font-family: $font-body;
    font-style: normal;
    font-weight: $font-weight;
    font-display: swap;
    src: url("./fonts/RRI-Body-#{$font-weight}.woff2") format("woff2");
  }
}

:root {
  --gap: #{rem(6)};
  --gutter: #{rem(16)};
  --card-gutter: #{rem(16)};
  --item-gutter: #{rem(12)};

  --hero-underlap: #{rem(40)};

  --container-max-width: #{rem(608)};

  --font-size-h1: #{rem(46)};
  --font-size-h2: #{rem(32)};
  --font-size-h3: #{rem(15)};
  --font-size-h4: #{rem(12)};
  --font-size-h5: #{rem(11)};
  --font-size-paragraph: #{rem(14)};
  --font-size-small: #{rem(13)};
  --font-size-large: #{rem(14)};

  --button-size-small: #{rem(10)};
  --button-size-medium: #{rem(12)};
  --button-size-large: #{rem(15)};

  --line-height-h4: #{rem(14)};

  --state-checkbox-size: #{rem(20)};

  @include min-width($bp-small) {
    --gutter: #{rem(24)};
    --card-gutter: #{rem(24)};
    --item-gutter: #{rem(16)};

    --font-size-h1: #{rem(52)};
    --font-size-h2: #{rem(36)};
    --font-size-large: #{rem(15)};

    --hero-underlap: #{rem(32)};
  }

  @include min-width($bp-medium) {
    --gap: #{rem(8)};
    --gutter: #{rem(32)};
    --card-gutter: #{rem(40)};
    --item-gutter: #{rem(20)};

    --container-max-width: #{rem(688)};

    --hero-underlap: #{rem(80)};

    --font-size-h1: #{rem(64)};
    --font-size-h2: #{rem(42)};
    --font-size-h3: #{rem(18)};
    --font-size-h4: #{rem(13)};
    --font-size-h5: #{rem(12)};
    --font-size-paragraph: #{rem(16)};
    --font-size-small: #{rem(14)};
    --font-size-large: #{rem(17)};

    --button-size-small: #{rem(12)};
    --button-size-medium: #{rem(13)};
    --button-size-large: #{rem(16)};

    --line-height-h4: #{rem(15)};

    --state-checkbox-size: #{rem(24)};
  }

  @include min-width($bp-large) {
    --gutter: #{rem(48)};
    --card-gutter: #{rem(48)};
    --item-guter: #{rem(28)};

    --container-max-width: #{rem(768)};

    --hero-underlap: #{rem(120)};

    --font-size-h1: #{rem(72)};
    --font-size-h2: #{rem(52)};
    --font-size-h3: #{rem(18)};
    --font-size-h4: #{rem(14)};
    --font-size-h5: #{rem(12)};
    --font-size-paragraph: #{rem(16)};
    --font-size-small: #{rem(15)};
    --font-size-large: #{rem(18)};

    --button-size-medium: #{rem(14)};
    --button-size-large: #{rem(18)};

    --line-height-h4: #{rem(16)};
  }
}

html {
  min-height: 100%;
}

html,
body,
#root {
  display: flex;
  flex-direction: column;
  flex: 1;
}

body {
  --focus-ring-color: #{$focus-ring-color};

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background: $color-600;
  color: $color-100;
  font-family: $font-family-body;
  line-height: $line-height-body;
}

a {
  text-decoration: underline;
  color: currentColor;
  font-weight: $fw-medium;
  transition: color $duration-fast $easing;
}

a,
summary {
  &:focus {
    outline: rem($focus-ring-width) var(--focus-ring-color) solid;
    outline-offset: rem($focus-ring-offset);
  }

  &:focus:not(:focus-visible) {
    outline: none;
  }

  &:focus-visible {
    outline: rem($focus-ring-width) var(--focus-ring-color) solid;
  }
}

@function summary-arrow-icon($color: red) {
  // 1. Convert the color from any valid Sass colour (i.e. red, rgba()) to the IE hex
  $color-escaped: color.ie-hex-str($color);
  // 2. Strip the alpha value and hash from it (#FFFF0000 -> FF0000)
  $color-escaped: string.slice($color-escaped, 4);

  $svg-start: "data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' xmlns='http://www.w3.org/2000/svg'%3E";
  $svg-path: "%3Cpath d='M5 3v10l7-5z' fill='%23#{$color-escaped}' fill-rule='evenodd'/%3E";
  $svg-end: "%3C/svg%3E";

  @return $svg-start + $svg-path + $svg-end;
}

details {
  font-size: var(--font-size-paragraph);
  padding-left: $list-item-padding;
}
summary {
  font-size: var(--font-size-paragraph);
  user-select: none;
  cursor: pointer;
  display: block;
  line-height: $line-height-body-tighter;
  margin-left: $list-item-padding * -1;
  padding-left: $list-item-padding;
  position: relative;

  &::-webkit-details-marker {
    display: none;
  }
  &::marker {
    display: none;
    content: "";
  }

  // Ideally we'd use display: grid; on summary and position this as one of the
  // grid children, but Safari has an unfixed bug for flex/grid on summary –
  // https://bugs.webkit.org/show_bug.cgi?id=190065
  &::before {
    background-position: -10% 50%; // Optically repositions the icon
    background-repeat: no-repeat;
    background-image: url(summary-arrow-icon($color-600));
    background-size: 80%;
    content: "";
    height: $line-height-body-tighter * 1em;
    width: $line-height-body-tighter * 1em;
    margin: 0 auto;
    opacity: $text-semi-transparent * 0.8; // Optically balances the arrow weight
    position: absolute;
    top: 0;
    left: calc(
      #{math.div($list-item-padding, 2)} - #{$line-height-body-tighter * 0.5em}
    );

    details[open] & {
      background-position: 60% 100%; // Optically repositions the icon
      transform: rotate(90deg);
    }
  }
}

pre {
  font-size: var(--font-size-small);
  white-space: pre-wrap;
  word-break: break-all;
}

p,
ol,
ul {
  max-width: 46em;
  font-size: var(--font-size-paragraph);
}

strong {
  font-weight: $fw-medium;
}

.text,
.text-reversed {
  > :first-child {
    margin-top: 0;
  }

  p,
  ul,
  ol {
    font-size: var(--font-size-large);
    margin-top: $text-relative-space * 4;
  }
}

.text {
  a {
    color: $color-accent-a11y;

    &:hover {
      color: $color-600;
    }
  }
}

.text-reversed {
  &,
  a,
  button {
    --focus-ring-color: #{$focus-ring-color-reversed};
  }

  a {
    &:hover {
      color: rgba($color-100, $text-semi-transparent);
    }
  }
}

.tabular-nums {
  font-variant-numeric: tabular-nums;
  letter-spacing: $letter-spacing-tabular-numbers;
}
.pre-wrap {
  white-space: pre-wrap;
}
.nowrap {
  white-space: nowrap;
}

// Class for hiding text for everyone except screenreaders
.sr-only {
  @include sr-only;
}
