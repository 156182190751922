@import "src/scss/global";

.HeroHeading {
  margin-bottom: 0;
}

.ResetButton {
  margin-top: var(--gutter);
}

.Error {
  margin-top: var(--gutter);

  pre {
    margin: $text-relative-space 0 0;
    max-width: 80ch;
  }
}
.ErrorTitle {
  margin: rem(2) 0 0; // Rebalances the title spacing
}
