@import "src/scss/global";

$unstacked-outcomes-width: 200;

.HeroContent,
.Container {
  @include min-width($bp-medium) {
    --container-max-width: #{rem(864)};
  }
  @include min-width($step-wrapper-unstack) {
    --container-max-width: #{rem(1184)};
  }
  @include min-width($bp-large) {
    --container-max-width: #{rem(1312)};
  }
}

.HeroContent {
  @include min-width($step-wrapper-unstack) {
    padding-left: calc(
      var(--gutter) + /* The left gutter */ #{rem($unstacked-outcomes-width)} + var(
          --gutter
        ) + /* The gutter between the sidebar and the cards */ var(
          --card-gutter
        ) /* To make it line up with the card */
    );
    padding-right: calc(var(--gutter) + var(--card-gutter));
    position: relative;

    &::before {
      content: attr(data-step-number);
      font-family: $font-family-heading;
      font-weight: $fw-heading;
      line-height: 0.835;
      text-align: center;
      position: absolute;
      left: var(--gutter);
      width: rem($unstacked-outcomes-width);
      display: block;
      font-size: calc(var(--font-size-h1) * 4);
      color: $color-accent;
    }
  }
}
.HeroStepNumber {
  color: $color-accent-light;

  @include min-width($step-wrapper-unstack) {
    display: none;
  }
}

.Outcomes {
  --small-heading-color: #{rgba($color-100, $text-semi-transparent)};
  --focus-ring-color: #{$focus-ring-color-reversed};

  padding-top: var(--gutter); // Offsets the spacing from the title slightly

  @include max-width($step-wrapper-unstack) {
    &-outcomesRoute {
      display: none;
    }
  }

  @include min-width($step-wrapper-unstack) {
    align-self: stretch;
    background: none;
    padding: 0;
    width: 100%;
    margin-top: var(--hero-underlap);
    position: relative;
  }
}
.OutcomesInner {
  @include min-width($step-wrapper-unstack) {
    top: 0;
    padding-top: var(--gutter);
    position: sticky;
  }
}
.OutcomesList {
  @include min-width($bp-large) {
    margin-left: $list-item-padding * -1;
  }
}

.Container {
  @include max-width($step-wrapper-unstack) {
    max-width: none;
    padding: 0;
  }

  @include min-width($step-wrapper-unstack) {
    align-items: flex-start;
    display: grid;
    grid-template-columns: rem($unstacked-outcomes-width) 1fr;
    grid-gap: var(--gutter);
  }
}
.ContainerCard {
  margin-bottom: var(--gutter);

  @include min-width($step-wrapper-unstack) {
    max-width: none;
    width: 100%;
  }
}
