@import "src/scss/global";

// Counteracts the track overlap. Note that this is not the same as var(--gap)
// and should not be changed to use it
$breadcrumbs-overlap: rem(8);
$bp-hide-breadcrumbs: 460;

.Wrapper {
  display: flex;
  align-items: flex-start;
  gap: var(--gutter);

  @include max-width($bp-medium) {
    gap: calc(var(--gap) * 2);
    flex-direction: column;
    flex-wrap: wrap;
  }
}

.Breadcrumbs {
  $breadcrumbs-regular-width: 9fr;
  // The wider item is slightly larger to optically balance it. If the shorter
  // items are all as long, they look strangely long, this ensures a better
  // balance.
  $breadcrumbs-wider-width: 10fr;

  display: grid;
  grid-template-columns:
    $breadcrumbs-regular-width
    $breadcrumbs-regular-width
    $breadcrumbs-wider-width
    $breadcrumbs-regular-width
    $breadcrumbs-regular-width
    auto; // Last item has no track so can be left to auto fill the space it needs
  list-style: none;
  padding: 0;
  padding-left: $breadcrumbs-overlap;

  @include max-width($bp-medium) {
    margin-left: auto;
    margin-right: auto;
    order: 2;
  }

  @include max-width($bp-hide-breadcrumbs) {
    // There isn't enough space to show this sensibly on small screens, so it
    // is hidden
    display: none;
  }
}
.BreadcrumbsItem {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  font-size: rem(12);
  transition: color $duration $easing;
  font-weight: $fw-medium;
  color: $color-500;
  margin-left: $breadcrumbs-overlap * -1;
  // To balance the longest items ("Consequences" namely) some aggressive
  // tactics had to be deployed. This might not seem like much, but saves enough
  // pixels to ensure that they use the space more carefully
  letter-spacing: -0.01em;

  &-future {
    color: $color-400;
  }

  // With width: 100%; on the label, this causes the element to be wider than
  // it needs to be, so the last item (as it has no track) can be set to be a
  // column rather than a row which saves the space
  &:last-child {
    align-items: flex-start;
    flex-direction: column;
  }

  @include min-width($bp-medium) {
    font-size: rem(13);
  }

  @include min-width($bp-large) {
    font-size: rem(14);
  }
}
.BreadcrumbsStateIndicator {
  display: flex;
  z-index: 2;
}
.BreadcrumbsTrack {
  flex: 1;
  background: $color-300;
  height: rem(2);
  display: block;
  margin-left: $breadcrumbs-overlap * -1;
  position: relative;
  overflow: hidden;

  &::before {
    background: $color-positive;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: "";
    transform: scale(0, 1);
    transition: transform $duration-slow $easing-in-out-back;
    transform-origin: center left;
  }

  &-past {
    background: $color-400;
  }

  &-completed {
    &::before {
      transform: scale(1, 1);
    }
  }

  .BreadcrumbsItem:last-child & {
    display: none;
  }
}
.BreadcrumbsLabel {
  margin-top: var(--gap);
  width: 100%;
  padding-right: calc(#{$breadcrumbs-overlap} + var(--gap));

  .BreadcrumbsItem:last-child & {
    padding-right: 0;
  }
}

.Progress {
  display: grid;
  align-items: center;
  grid-template-columns: 1fr;
  margin-left: auto;
  margin-right: auto;
  width: 100%;

  @include min-width($bp-medium) {
    flex: 1;
    margin-right: 0;
    max-width: $block-button-size;
  }

  [aria-hidden] {
    grid-area: 1 / 1;
    transition: opacity $duration-fast $duration-fast $easing,
      visibility 0ms 0ms;
    justify-self: center;
  }

  [aria-hidden="true"] {
    opacity: 0;
    visibility: hidden;
    transition: opacity $duration-fast $easing, visibility 0ms $duration-fast;
  }

  > p {
    line-height: $line-height-body-tighter;
    font-weight: $fw-medium;
    color: $color-disabled;
    grid-area: 1 / 1;
    display: flex;
    align-items: center;
    text-align: center;
  }
}
