@import "src/scss/global";

$nav-link-height: 52;
$nav-border-height: 2;

$bp-nav-smallest: 345;
$bp-nav-small: 460;
$bp-nav-stack: 580;

.Header {
  padding: 0;
}

.Nav {
  display: flex;
  list-style: none;
  padding: 0;
  max-width: none;
  // Offsets the padding added at the top to fix the underscroll
  margin-top: rem(-$card-border-radius);

  @include max-width($bp-nav-small) {
    padding-left: var(--gutter);
  }
}
.NavItem {
  flex-grow: 1;
  text-align: center;

  @include min-width($bp-nav-small) {
    max-width: 25%;
  }

  &-pagination {
    flex-grow: 0;
    width: var(--card-gutter);

    @include max-width($bp-nav-small) {
      display: none;
    }

    &:last-child {
      margin-left: auto;
    }
  }
}
.NavLink,
.NavPagination {
  position: relative;

  &::after {
    @include inset(calc(var(--gap) / 2));

    content: "";
    border: solid rem($focus-ring-width) var(--focus-ring-color);
    position: absolute;
    border-radius: rem(4);
    opacity: 0;
  }

  &:focus {
    outline: none;

    &::after {
      opacity: 1;
    }
  }
  &:focus:not(:focus-visible) {
    &::after {
      display: none;
    }
  }
}
.NavLink {
  appearance: none;
  border: 0;
  background: none;
  cursor: pointer;
  gap: var(--gap);
  border-bottom: rem($nav-border-height) solid $color-200;
  padding-right: var(--gap);
  padding-top: rem($nav-border-height); // Offsets the border-bottom
  height: rem($nav-link-height);
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  text-decoration: none;
  width: 100%;

  &::after {
    left: rem(-8);
  }

  @include max-width($bp-nav-stack) {
    text-align: left;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }

  @include min-width($bp-nav-small) {
    padding-right: calc(var(--gap) * 2);
  }

  &:hover {
    border-color: $color-400;
  }

  &[aria-selected="true"] {
    border-color: $color-accent;
  }
}
.NavLabel {
  margin: 0;

  @include max-width($bp-nav-stack) {
    letter-spacing: 0;
    font-size: rem(11);
  }

  // Vertically centers the text
  @include min-width($bp-nav-stack) {
    margin-top: rem(2);
  }

  @include min-width($bp-medium) {
    margin-top: rem(1);
  }

  @include min-width($bp-large) {
    margin-top: 0;
  }
}
.NavLabelHideShort {
  @include max-width($bp-nav-smallest) {
    display: none;
  }
}
.NavPagination {
  background: none;
  border: 0;
  appearance: none;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  display: flex;
  height: rem($nav-link-height);
  width: 100%;

  &::after {
    border-top-right-radius: calc(
      #{rem($card-border-radius)} - (var(--gap) / 2)
    );
    height: calc(#{rem($nav-link-height)} - var(--gap));
    width: calc(var(--card-gutter) - var(--gap));
  }

  &-previous {
    transform: scale(-1, 1);
  }
}
