@import "src/scss/global";

.Footer {
  color: mix($color-200, $color-600);
  padding-bottom: calc(var(--gutter) / 2);
  margin-top: auto;

  p,
  ul {
    font-size: var(--font-size-small);
    margin: 0;
  }
}

.Container {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;
  max-width: none;
  gap: $text-relative-space var(--gutter);

  ul {
    list-style: none;
    display: flex;
    gap: var(--gap);
    padding-left: 0;

    a {
      text-decoration: none;

      &:hover,
      &:focus {
        text-decoration: underline;
        color: $color-100;
      }
    }
  }
}
