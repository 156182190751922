@import "src/scss/global";

.Hero {
  background: $color-accent-a11y;
  color: $color-100;
  padding-top: calc(var(--gutter) * 1.5);
  padding-bottom: calc(var(--gutter) * 1.25 + var(--hero-underlap));
  margin-bottom: calc(var(--hero-underlap) * -1);
  display: flex;
  flex-direction: column;

  @include min-width($bp-medium) {
    min-height: 33vh;
  }

  &-verticallyCenter {
    justify-content: center;
  }

  p {
    // There is a lot of copy in the hero and it is broken into lots of small
    // paragraphs, so this mitigates it slightly, whilst maintaining the
    // spacing between paragraphs
    margin-top: $text-relative-space * 2;
  }
}
