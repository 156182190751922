@use "sass:math";

@import "src/scss/global";

.Beacon {
  --beacon-size: #{rem(6)};

  background: $color-accent;
  border-radius: 50%;
  height: var(--beacon-size);
  width: var(--beacon-size);
  display: block;
  position: absolute;
  top: 50%;
  left: calc(var(--card-gutter) / -2);
  margin: calc(var(--beacon-size) / -2);
  transition: opacity $duration $duration $easing,
    transform $duration-slow $easing-in-out-back;

  &-hidden {
    transform: scale(0);
    opacity: 0;
  }

  @include min-width($bp-small) {
    --beacon-size: #{rem(10)};
  }

  &::before,
  &::after {
    @include inset(-150%);
    border-radius: 50%;
    content: "";
    position: absolute;
    background: $color-accent;
    animation: pulse 3s ease-in-out infinite;
    opacity: 0.2;
    transform: scale(0.5);

    @keyframes pulse {
      0% {
        transform: scale(0.25);
        opacity: 0;
      }
      33.3% {
        opacity: 0.2;
      }
      100% {
        transform: scale(1);
        opacity: 0;
      }
    }
  }

  &::after {
    animation-delay: -1.5s;
  }
}
