@import "src/scss/global";

$button-line-height: 1;
$button-vertical-padding: 0.25em;

.Button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  appearance: none;
  background: none;
  border: 0;
  color: inherit;
  cursor: pointer;
  font-weight: $fw-medium;
  text-decoration: none;
  padding: $button-vertical-padding 1.25em;
  position: relative;
  line-height: $button-line-height;
  min-height: $button-line-height * 2em + ($button-vertical-padding * 2);
  text-align: center;
  text-transform: none;
  transition: opacity $duration $easing,
    transform $duration-fast $easing-in-out-back;

  &::before,
  &::after {
    border-radius: rem(100);
    position: absolute;
    content: "";
    @include inset(0);
  }

  &::before {
    background: var(--button-background);
    opacity: var(--button-background-alpha, 1);
    transition: background $duration $easing, opacity $duration $easing,
      transform $duration-slow $easing-in-out-back;
  }

  &::after {
    @include inset(rem(-$focus-ring-width - $focus-ring-offset));

    border: solid rem($focus-ring-width) var(--focus-ring-color);
    transition: transform $duration-slow $easing-in-out-back;
    opacity: 0;
  }

  &:hover:not([disabled]) {
    &::before,
    &::after {
      transform: scale(0.975);
    }
  }

  &:focus {
    outline: none;

    &::after {
      opacity: var(--button-background-alpha, 1);
    }
  }
  &:focus:not(:focus-visible) {
    &::after {
      display: none;
    }
  }

  &:active:not([disabled]) {
    transform: scale(0.975);
  }

  &[disabled] {
    --button-background: currentColor;
    --button-background-alpha: 0.05;
    --button-color: currentColor;

    cursor: default;
    opacity: 0.5;
  }
}

.Children {
  color: var(--button-color);
  position: relative;
  transition: color $duration $easing;
  width: 100%;
}

.Arrow {
  color: var(--button-color);
  position: relative;
  margin-right: -0.5em;
  margin-left: 0.5em;
  width: 1em;
  height: 1em;
  transition: $duration $easing;
  transition-property: color, opacity;

  path {
    fill: currentColor;
  }

  [disabled] & {
    // Makes the arrow slightly less prominent when disabled to optically
    // balance the opacity of the text
    opacity: 0.8;
  }
}

// Colors
.Button {
  &-default {
    --focus-ring-color: #{rgba($color-accent-light, 0.4)};

    --button-background: #{$color-accent};
    --button-color: #{$color-600};
  }

  &-reversed {
    --focus-ring-color: currentColor;

    --button-background: currentColor;
    --button-background-alpha: 0.1;
  }

  &-inactive {
    --focus-ring-color: #{rgba($color-500, 0.3)};

    --button-background: #{$color-500};
    --button-color: #{$color-100};
  }

  &-complete {
    --focus-ring-color: #{rgba($color-positive, 0.5)};

    --button-background: #{$color-positive};
    --button-color: #{$color-600};
  }

  &-negative {
    --focus-ring-color: #{rgba($color-negative, 0.3)};

    --button-background: #{$color-negative};
    --button-color: #{$color-100};
  }
}

// Sizes
.Button {
  &-small {
    font-size: var(--button-size-small);
    // Smaller buttons are the same font-size etc as medium buttons, but have
    // a shorter min-height as they are likely to rarely go to two lines
    min-height: $button-line-height * 1.5em + ($button-vertical-padding * 2);
    padding-left: 1em;
    padding-right: 1em;
  }

  &-medium {
    font-size: var(--button-size-medium);
  }

  &-large {
    font-size: var(--button-size-large);
  }
}

// Variants
.Button {
  &-block {
    width: 100%;

    @include min-width($bp-small) {
      max-width: 100%;
      width: $block-button-size;
    }
  }
}
