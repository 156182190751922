@import "src/scss/global";

// By using --gutter for the box-shadow, it sizes down as the card gets smaller
$card-box-shadow-y: calc(var(--gutter) / 2);
$card-box-shadow-z: calc(var(--gutter) * 2);

.Card {
  background: $color-100;
  border-radius: rem($card-border-radius);
  box-shadow: rgba(black, 0.11) 0 $card-box-shadow-y $card-box-shadow-z;
  color: $color-600;
  padding: var(--card-gutter);
  margin: 0 auto var(--gutter);
  max-width: calc(
    /* Makes it wider than the .Container so that the text lines up */
      var(--container-max-width) + var(--card-gutter) - var(--gutter)
  );
  width: calc(100% - (var(--gutter) * 2));

  hr {
    width: 100%;
    display: block;
    border: 0;
    height: rem(1);
    background: $color-300;
    margin: var(--gutter) 0 0;
  }
}

.Card {
  input,
  textarea,
  button,
  :target,
  [tabindex="0"] {
    scroll-margin-top: calc(var(--card-scroll-margin-top, 0) * #{rem(1)});
    scroll-margin-bottom: calc(var(--card-scroll-margin-bottom, 0) * #{rem(1)});
  }

  &-withStickyHeader {
    --card-scroll-margin-top: 80;

    background: $color-200;
    scroll-padding-top: calc(var(--card-scroll-margin-top) * #{rem(1)});
  }
  &-withStickyFooter {
    --card-scroll-margin-bottom: 120;

    background: $color-200;
    scroll-padding-bottom: calc(var(--card-scroll-margin-bottom) * #{rem(1)});
  }
  &-reversed {
    background: $color-500;
    color: $color-100;
    box-shadow: none;
  }
}

// Because the sticky header and footer have border-radius we have a complex
// layout required to prevent a small portion of the background of the card
// peeking through. It consists of three elements:
//  1. The "end" of the sticky that has the roundeded corners that is not sticky
//     and thus scrolls out of view as expected
//  2. The "spacer" that is equal to the height that sticks to the viewport end
//  3. The sticky element itself that sticks with an offset equal to the size
//     of the rounded corner

$vertical-padding: calc(var(--gutter) / 2);

.StickyEnd,
.StickySpacer,
.StickyElement {
  background: $color-100;
  width: calc(100% + (var(--card-gutter) * 2));
  margin-left: calc(var(--card-gutter) * -1);
  margin-right: calc(var(--card-gutter) * -1);
  z-index: 2;

  @media screen and (min-height: 32em) {
    position: sticky;
  }
}

.StickyEnd {
  height: rem($card-border-radius);
  display: block;
  position: relative;

  &-header {
    margin-top: calc(var(--card-gutter) * -1);
    border-top-left-radius: rem($card-border-radius);
    border-top-right-radius: rem($card-border-radius);
  }

  &-footer {
    margin-bottom: calc(var(--card-gutter) * -1);
    border-bottom-left-radius: rem($card-border-radius);
    border-bottom-right-radius: rem($card-border-radius);
  }
}

// The spacer is 1px taller than the space requires to handle any browser
// aliasing inconsistencies
.StickySpacer {
  height: rem($card-border-radius + 1);

  &-header {
    top: rem(-1);
    margin-bottom: rem(-$card-border-radius - 1);
  }

  &-footer {
    bottom: rem(-1);
    margin-top: rem(-$card-border-radius - 1);
  }
}

.StickyElement {
  padding: $vertical-padding var(--card-gutter);
  z-index: 3;

  &-header {
    border-bottom: rem(1) solid $color-300;
    padding-top: calc(#{$vertical-padding} - #{rem($card-border-radius)});
    top: rem($card-border-radius);
    margin-bottom: var(--card-gutter);
  }

  &-footer {
    border-top: rem(1) solid $color-300;
    padding-bottom: calc(#{$vertical-padding} - #{rem($card-border-radius)});
    bottom: rem($card-border-radius);
    margin-top: var(--card-gutter);
  }
}
