@use "sass:math";

@import "src/scss/global";

$toggle-state-offset: 4;

.Children {
  align-items: center;
  display: grid;
  grid-template-columns: 1fr auto;
}

.Label {
  grid-row: 1;
  grid-column: 1;
  transition: opacity $duration-fast $easing;
  transition-delay: $duration-fast;

  &-unchecked {
    grid-column: 1 / span 2;
  }

  &-matched {
    // Accounts for the space created by the StateIndicator
    $toggle-state-width: $state-indicator-width + $toggle-state-offset;
    padding: 0 calc((#{rem($toggle-state-width)} - var(--gap)) / 2);
  }

  &[aria-hidden="true"] {
    opacity: 0;
    transition-delay: 0ms;
  }
}

.StateIndicator {
  background: $color-100;
  margin-right: rem($toggle-state-offset * -1);
  margin-left: var(--gap);
  grid-row: 1;
  grid-column: 2;

  path {
    fill: $color-600;
  }

  .Toggle:not(.Toggle-checked) & {
    background: transparent;
    transform: scale(0);
  }
}
