@import "src/scss/global";

.Header {
  background: $color-100;
  color: $color-600;
}

.Container {
  display: flex;
  min-height: rem(48);
  align-items: center;
  max-width: none;

  @include min-width($bp-small) {
    min-height: rem(64);
  }
}

.Link {
  display: block;
  color: inherit;
  // Increases the hitstate of the logo slightly
  padding: rem(10);
  margin: rem(-10);
  transition-duration: $duration;

  &:hover {
    opacity: $text-semi-transparent;
  }
}

.Logo {
  display: block;
  height: auto;
  width: rem(140);

  @include min-width($bp-small) {
    width: rem(180);
  }

  path {
    fill: currentColor;
  }
}
