@import "src/scss/global";

.HeroHeading {
  margin: 0;

  strong {
    color: $color-accent-light;
    display: block;
  }

  @include min-width(320) {
    font-size: calc(var(--font-size-h1) * 1.25);
  }
}
.HeroStrapline {
  font-size: calc(var(--font-size-large) * 1.25) !important;
  font-weight: $fw-medium;
  margin-top: $text-relative-space !important;
  margin-bottom: 1.5em !important;
}

.Buttons {
  display: flex;
  justify-content: flex-end;
  margin-top: var(--gutter);
}
