@use "sass:math";

@import "src/scss/global";

$consequence-edit-button-size: 45;
$consequence-gap: calc(var(--gap) * 2);
$consequence-right-padding: calc(var(--gutter) / 2);
$consequence-drag-handle: 8;

$bp-stack-consequence: $bp-medium;

$consequence-button-and-handle: rem($consequence-edit-button-size) +
  rem($consequence-drag-handle) + rem($item-border-width);
$consequence-helper-right-padding: calc(
  #{$consequence-gap} + #{$consequence-button-and-handle} + #{$consequence-right-padding}
);

.Consequences {
  --evaluation-height: #{rem(32)};
  --evaluation-width: #{rem(50)};
  --evaluation-arrow-size: #{rem(12)};

  @include min-width($bp-stack-consequence) {
    --evaluation-height: #{rem(40)};
    --evaluation-width: #{rem(60)};
    --evaluation-arrow-size: #{rem(14)};
  }
}

.Heading {
  position: relative;
}

.Helper {
  align-items: flex-end;
  display: grid;
  grid-template-columns: 1fr var(--evaluation-width) var(--evaluation-width) $consequence-helper-right-padding;
  gap: $consequence-gap;

  @include max-width($bp-stack-consequence) {
    display: block;
  }
}
.HelperText {
  color: $color-disabled;
  margin-top: $text-relative-space;
}
.HelperLabel {
  margin: 0;
  justify-self: center;

  &-hidden {
    visibility: hidden;
  }

  @include max-width($bp-stack-consequence) {
    display: none;
  }
}

.DraggableList-withItems {
  margin-top: var(--gap);
}

.Consequence {
  &-notEditing {
    padding: var(--gap) var(--item-gutter);
    padding-right: #{$consequence-right-padding};
  }
}
.ConsequenceWrapper {
  display: grid;
  align-items: center;
  grid-template-columns: 1fr auto;
  gap: $consequence-gap;
  flex-wrap: wrap;

  @include min-width($bp-stack-consequence) {
    grid-template-columns: 1fr auto auto;
  }
}
.ConsequenceEdit {
  display: flex;
  grid-column: 2;

  @include min-width($bp-stack-consequence) {
    padding-left: 0;
    padding-right: 0;
    width: rem($consequence-edit-button-size);
    grid-column: 3;
  }
}
.ConsequenceText {
  display: flex;
  flex-direction: column;
  font-weight: $fw-medium;
  justify-content: center;
  line-height: $line-height-body-tighter;
  word-break: break-word;
  max-width: 42em;
  min-height: var(--evaluation-height);
  margin-right: auto;
  width: 100%;

  @include min-width($bp-stack-consequence) {
    flex: 1;
  }
}
.ConsequenceEvaluations {
  display: flex;
  gap: $consequence-gap;

  @include max-width($bp-stack-consequence) {
    order: 3;
    gap: var(--gap) calc(var(--gap) * 2);
    flex-wrap: wrap;
    grid-column: 1 / span 2;
  }
}

.CreationForm {
  --creation-form-button-min-width: #{$option-creation-form-button-size};

  margin-top: var(--gap);
}

$consequence-values: 10;
$select-border-width: 2;
$select-border-radius: 4;
$select-border-lowest-opacity: 0.2;

@function mix-consequence-color($value, $base-color) {
  $percent: math.div(1, $consequence-values - 1) * ($value - 1);
  // Ensures that the lowest value is observed for $value=1
  $normalized-percent: $percent * (1 - $select-border-lowest-opacity) +
    $select-border-lowest-opacity;
  @return rgba($base-color, $normalized-percent);
}

.Evaluation {
  display: flex;
  align-items: center;
  gap: var(--gap);
}
.EvaluationLabel {
  margin: rem(1) 0 0;

  @include min-width($bp-stack-consequence) {
    @include sr-only;
  }
}
.EvaluationSelect {
  display: inline-flex;
  align-items: center;
  border-radius: rem($select-border-radius);
  border: $fieldset-border-color rem($select-border-width) solid;
  justify-content: center;
  color: $color-600;
  appearance: none;
  height: var(--evaluation-height);
  width: var(--evaluation-width);
  position: relative;

  @for $value from 1 through $consequence-values {
    &[data-value="#{$value}"] {
      --upsides-color: #{mix-consequence-color($value, $color-positive)};
      --downsides-color: #{mix-consequence-color($value, $color-negative)};
    }
  }

  &:focus-within {
    &::before {
      @include inset(rem(-$focus-ring-width - $select-border-width));

      border-radius: rem($select-border-radius + $focus-ring-width);
      border: rem($focus-ring-width) solid var(--focus-ring-color);
      content: "";
      position: absolute;
      opacity: 1;
    }
  }

  &-upsides {
    border-color: var(--upsides-color);
  }
  &-downsides {
    border-color: var(--downsides-color);
  }

  &[data-value="0"] {
    border-color: $color-300;
    color: $color-400;
  }

  select {
    appearance: none;
    opacity: 0;
    cursor: pointer;
    background: none;
    border: 0;
    outline: none;
    position: absolute;
    top: rem($select-border-width * -1);
    left: rem($select-border-width * -1);
    height: calc(100% + #{rem($select-border-width * 2)});
    width: calc(100% + #{rem($select-border-width * 2)});
  }
}
.EvaluationTooltip {
  left: 0;
  top: 0;
  z-index: 2;
}
.EvaluationValue {
  font-variant-numeric: tabular-nums;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  color: currentColor;
  padding-left: calc(var(--evaluation-arrow-size) / 2);
  text-align: center;
  font-size: var(--font-size-small);
  padding-top: rem(1);
  font-weight: $fw-bold;

  @media (pointer: fine) {
    padding-left: var(--evaluation-arrow-size);
  }
}
.EvaluationArrows {
  width: var(--evaluation-arrow-size);

  svg {
    display: block;
    transform: rotate(90deg);
    height: rem(9);
    width: rem(9);
    color: $color-400;
    transition: color $duration $easing;

    @media (pointer: fine) {
      color: transparent;
    }

    &:first-child {
      transform: rotate(-90deg);
      margin-bottom: rem(-3);
    }

    path {
      fill: currentColor;
    }
  }

  .EvaluationSelect:hover & {
    svg {
      color: $color-500;
    }
  }
}
