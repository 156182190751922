@use "sass:math";

$bp-small: 420;
$bp-medium: 840;
$bp-large: 1260;

$bp-unstack-forms: 620;

$color-100: #ffffff;
$color-200: #f7f7f9;
$color-300: #dee3e9;
$color-400: mix(#dee3e9, #303a46);
$color-500: #303a46;
$color-600: #0b131d;

$color-disabled: mix($color-500, $color-400, 35%); // 4.5:1 against $color-200

$color-accent: #48c4e4;
$color-accent-light: #92e5fa;
$color-accent-a11y: #0c93bd;

$color-positive: #9ecb3d;
$color-negative: darken(#eb3d3d, 10%); // Makes it 4.5:1
$color-warning: #f4cb00;

$text-semi-transparent: 0.65;
$text-relative-space: 0.25em;

$font-body: "RRI-Body";
$font-heading: "RRI-Heading";

$font-family-body: $font-body, sans-serif;
$font-family-heading: $font-heading, Impact, sans-serif;

$line-height-body: 1.5;
$line-height-body-tighter: 1.25;
$line-height-heading: 0.9;

$letter-spacing-tabular-numbers: -0.05em;

$fw-bold: 800;
$fw-medium: 600;
$fw-regular: 400;

$fw-heading: 600;

$heading-h2-max-width: 15em;

$duration: 200ms;
$duration-fast: 100ms;
$duration-slow: 400ms;

$easing: ease-in-out;
$easing-in-out-back: cubic-bezier(0.68, -0.6, 0.32, 1.6);
$easing-out-back: cubic-bezier(0.34, 1.56, 0.64, 1);

$card-border-radius: 10;

$item-border-radius: 4;
$item-border-width: 1;

$fieldset-height: 36;
$fieldset-line-height: 22;
$fieldset-border-width: 1;
$fieldset-border-radius: 4;
$fieldset-padding: math.div($fieldset-height - $fieldset-line-height, 2);
$fieldset-border-color: mix($color-300, $color-400);

$step-wrapper-unstack: 1080;

$state-indicator-width: 14;

$state-checkbox-border-radius: 4;
$state-checkbox-border-width: 1;

$focus-ring-width: 3;
$focus-ring-color: mix(white, $color-accent-light, 60%);
$focus-ring-color-reversed: rgba($color-100, 0.4);
$focus-ring-offset: 2; // This is only used where the ring is set away from the item

$list-item-padding: 1.75em;
$list-ol-marker-padding: 0.25em;

$block-button-size: calc(var(--button-size-large) * 14);
// Magic number to make the Add Upside/Downside/Mitigation buttons all the same width
$option-creation-form-button-size: 10.5em;
// Magic number to make Save/Cancel buttons the same width
$editing-save-cancel-form-button-size: 5.5em;
