@import "src/scss/global";

.Item {
  background: $color-100;
  padding: calc(var(--gutter) / 2);
  border: rem($item-border-width) solid $color-300;
  border-radius: rem($item-border-radius);
}

// Draggable item styles
.Item {
  &-draggable {
    display: grid;
    grid-template-columns: 1fr auto;
    gap: calc(var(--gap) * 2);
    margin-bottom: var(--gap);
    position: relative;

    &::before {
      @include inset(rem(-$focus-ring-width - 1));

      content: "";
      border: rem($focus-ring-width) solid var(--focus-ring-color);
      border-radius: rem($item-border-radius + $focus-ring-width);
      position: absolute;
      opacity: 0;
    }

    &:focus {
      outline: none;

      &::before {
        opacity: 1;
      }
    }
  }

  &-dragging {
    background: rgba($color-100, 0.65);
    box-shadow: rgba($color-600, 0.07) rem(2) rem(4) rem(12);
  }
}
.ItemHandle {
  margin: auto;
  transition: opacity $duration-fast $easing;
  opacity: 0.33;

  path {
    fill: $color-500;
  }

  .Item:hover &,
  .Item:focus & {
    opacity: 0.5;
  }
  .Item-dragging & {
    opacity: 0.66 !important;
  }
}
