@import "src/scss/global";

.StateCheckbox {
  display: flex;
  background: $fieldset-border-color;
  border-radius: rem($state-checkbox-border-radius);
  width: var(--state-checkbox-size);
  height: var(--state-checkbox-size);
  transition: background $duration-fast $easing;
  position: relative;
  overflow: hidden;

  &::before {
    @include inset(rem($state-checkbox-border-width));
    background: $color-100;
    content: "";
    position: absolute;
    border-radius: rem(
      $state-checkbox-border-radius - $state-checkbox-border-width
    );
    transition: opacity $duration-fast $easing;
  }

  svg {
    width: rem(16);
    height: rem(16);
    margin: auto;
    transition: transform 0ms $duration-fast;
    position: relative;
    transform: scale(0);
  }

  path {
    fill: $color-100;
  }

  &-checked {
    background: $color-positive;
    transition: background $duration-fast $easing;

    &::before {
      transform: scale(0);
      transition: transform $duration ease-in, opacity 0ms $duration-slow;
      opacity: 0;
    }

    svg {
      transform: none;
      transition: transform $duration $duration-fast $easing-in-out-back;
    }
  }
}
