@use "sass:math";

@import "src/scss/global";

.StateIndicator {
  display: inline-flex;
  background: currentColor;
  border-radius: rem(math.div($state-indicator-width, 2));
  width: rem($state-indicator-width);
  height: rem($state-indicator-width);
  transition: background $duration $duration $easing,
    transform $duration-slow $easing-in-out-back;
  position: relative;

  &::before {
    background: $color-100;
    border-radius: inherit;
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: transform $duration-slow $easing-in-out-back;
    transform: scale(0);
  }

  svg {
    width: rem(10);
    height: rem(10);
    margin: auto;
    transition: opacity $duration $easing;
    position: relative;
  }

  path {
    fill: $color-100;
  }

  &-future {
    background: $color-400;
    transform: scale(0.5);

    svg {
      opacity: 0;
    }
  }

  &-active {
    transform: scale(math.div(10, $state-indicator-width));

    &::before {
      transform: scale(0.7);
    }

    svg {
      opacity: 0;
    }
  }

  &-completed {
    background: $color-positive;
    transition: background $duration $easing,
      transform $duration-slow $easing-in-out-back;
  }
}
