@import "src/scss/global";

.Form {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: var(--gap);
}

.Fieldset {
  margin: 0;
  width: 100%;

  @include min-width($bp-unstack-forms) {
    flex: 1;
  }
}

.Save {
  min-width: $editing-save-cancel-form-button-size;
  padding-left: 0;
  padding-right: 0;
}
