@import "src/scss/global";

.Form {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  gap: var(--gap);

  &-withMarginTop {
    margin-top: var(--gutter);
  }
}

.Fieldset {
  margin: 0;
  width: 100%;

  @include min-width($bp-unstack-forms) {
    flex: 1;
  }
}

.Buttons {
  @include min-width($bp-unstack-forms) {
    display: flex;
    align-items: center;
    min-height: rem($fieldset-height);

    .Form-withLabel & {
      margin-top: calc(var(--line-height-h4) + var(--gap));
    }
  }

  > button {
    min-width: var(--creation-form-button-min-width, 0);
  }
}
