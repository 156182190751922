@use "sass:math";

@import "src/scss/global";

.Fieldset {
  --fieldset-background: #{$color-100};
  --fieldset-color: #{$color-600};
  --fieldset-label-opacity: #{$text-semi-transparent};
  --fieldset-placeholder: #{rgba($color-600, 0.5)};
  --fieldset-border: #{$fieldset-border-color};
  --fieldset-error: #{$color-negative};

  display: flex;
  flex-wrap: wrap;
  margin-top: calc(var(--gutter) / 2);
  line-height: rem($fieldset-line-height);

  &-reversedColor {
    --fieldset-background: transparent;
    --fieldset-color: #{$color-100};
    --fieldset-label-opacity: 0.9;
    --fieldset-placeholder: #{rgba($color-100, $text-semi-transparent)};
    --fieldset-border: #{rgba($color-100, 0.6)};
    --fieldset-error: #{$color-100};
  }
}

.Label {
  margin: 0;
  color: currentColor;
  opacity: var(--fieldset-label-opacity);
  transition: opacity $duration $easing;
  display: flex;
  margin-bottom: var(--gap);
  max-width: none;
  width: 100%;

  .Fieldset:focus-within & {
    opacity: 1;
  }
}
.LabelCounter {
  color: $color-400;
  margin-left: auto;
  opacity: 0;
  transition: opacity $duration $easing;

  .Fieldset:focus-within & {
    opacity: 1;
  }
}

.InputAndError {
  width: 100%;
}
.InputWrapper {
  position: relative;
}
.Input {
  background: var(--fieldset-background);
  border: rem($fieldset-border-width) solid var(--fieldset-border);
  border-radius: rem($fieldset-border-radius);
  color: var(--fieldset-color);
  display: block;
  min-height: rem($fieldset-height);
  padding: rem($fieldset-padding - $fieldset-border-width) var(--gap);
  width: 100%;
  transition: border-color $duration $easing;

  &-textarea {
    $fieldset-vertical-offset: ($fieldset-padding + $fieldset-border-width) * 2;
    resize: none;
    min-height: calc(
      #{rem($fieldset-vertical-offset)} + (#{rem($fieldset-line-height)} * var(--min-rows))
    );
    max-height: calc(
      #{rem($fieldset-vertical-offset)} + (#{rem($fieldset-line-height)} * var(--max-rows))
    );
  }

  &:focus {
    outline: none;

    + .FocusRing {
      @include inset(rem(-$focus-ring-width));
      border: rem($focus-ring-width) solid var(--focus-ring-color);
      border-radius: rem($fieldset-border-radius + $focus-ring-width);
      position: absolute;
    }
  }

  &-hasError {
    border-color: var(--fieldset-error) !important; // Wins over :focus
  }

  &::placeholder {
    color: var(--fieldset-placeholder);
  }
}

.Error {
  color: var(--fieldset-error);
}
