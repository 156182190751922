@import "src/scss/global";

.SubmissionCard {
  background: $color-accent-a11y;
  color: $color-100;

  label {
    &:first-child {
      margin-top: 0;
    }
  }
}
.SubmissionTerms {
  margin-top: var(--gutter);
  display: flex;
  flex-wrap: wrap;
  gap: calc(var(--gutter) / 2);
  align-items: flex-start;

  @include min-width($bp-medium) {
    display: grid;
    grid-template-columns: 1fr auto;
  }

  p {
    line-height: $line-height-body-tighter;
    margin: 0;
    font-size: var(--font-size-small);
  }

  small {
    font: inherit;
    display: block;

    & + small {
      margin-top: $text-relative-space * 2;
    }
  }

  a {
    @include min-width($bp-small) {
      white-space: nowrap;
    }
  }
}
.SubmissionButtons {
  display: grid;
  gap: var(--gap);
  width: 100%;

  @include min-max-width($bp-small, $bp-medium) {
    grid-template-columns: auto 1fr;
    align-items: center;
    justify-items: flex-start;
  }

  @include min-width($bp-medium) {
    grid-column: 2;
    grid-row: 1 / span 2;
  }
}
.SubmissionError {
  color: currentColor;
}

// .Villain {
//   background: $color-100;
//   color: $color-600;
//   padding: calc(var(--gutter) * 2) 0;
//   margin-bottom: var(--gutter);
// }

// .HelpCard {
//   margin-top: var(--gutter);
//   margin-bottom: 0;
// }
// .HelpCardCta {
//   display: flex;
//   justify-content: flex-end;
//   margin-top: var(--gutter);
// }
