@import "src/scss/global";

.Heading {
  &-h1,
  &-h2 {
    margin-top: 1em;
    margin-bottom: 0.25em;
    text-transform: uppercase;
    font-family: $font-family-heading;
    font-weight: $fw-heading;
    line-height: $line-height-heading;
  }

  &-h1 {
    font-size: var(--font-size-h1);
    max-width: 9.5em;
  }

  &-h2 {
    font-size: var(--font-size-h2);
    max-width: $heading-h2-max-width;
  }

  &-h3 {
    font-weight: $fw-medium;
    font-size: var(--font-size-h3);
    line-height: $line-height-body-tighter;
    margin-top: 1.5em;
  }

  &-h4,
  &-h5 {
    letter-spacing: 0.01em;
    font-family: $font-family-body;
    font-weight: $fw-bold;
    color: var(
      --small-heading-color,
      #{rgba($color-600, $text-semi-transparent)}
    );
    max-width: 24em;
    text-transform: uppercase;
    margin-bottom: 0.25em;
  }

  &-h4 {
    font-size: var(--font-size-h4);
    // H4 takes a fixed line height to make it easier to style input labels etc
    line-height: var(--line-height-h4);
    margin-top: 1.5em;
  }

  &-h5 {
    font-size: var(--font-size-h5);
    line-height: $line-height-body-tighter;
    margin-top: 1em;
  }
}

.Heading {
  &-noMarginTop {
    margin-top: 0;
  }

  &-withButton {
    color: currentColor;
    display: flex;
    align-items: center;
    gap: var(--gap);

    &.Heading-h4 {
      margin-bottom: var(--gap);

      > span {
        color: var(
          --small-heading-color,
          #{rgba($color-600, $text-semi-transparent)}
        );
      }

      > button {
        margin-top: $text-relative-space * -1;
        margin-bottom: $text-relative-space * -1;
      }
    }
  }
}
