@use "sass:math";

@import "src/scss/global";

$bp-show-full-button-labels: 560;

.Card {
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  @include max-width($bp-medium) {
    --card-scroll-margin-bottom: 140;
  }

  @include max-width($bp-show-full-button-labels) {
    --card-scroll-margin-bottom: 80;
  }
}

.OutcomesHeading {
  position: relative;
}
.OutcomesHelper {
  color: $color-disabled;
  margin-top: $text-relative-space;
}

.RelatedOutcomes {
  appearance: none;
  padding: 0;
  outline: none;
  border: 0;
  margin: var(--gap) 0 0;
  font-size: var(--font-size-paragraph);

  label {
    cursor: pointer;
    display: grid;
    grid-template-columns: auto 1fr;
    line-height: $line-height-body-tighter;
    gap: var(--gap);
    min-height: var(--state-checkbox-size);
    align-items: flex-start;

    + label {
      margin-top: var(--gap);
    }
  }
}
.RelatedOutcomesCheckbox {
  // Forces the content to be the height of a line so that it is aligned accordingly
  height: $line-height-body-tighter * 1em;
  width: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin: 0;

  input {
    @include inset(0);
    border: 0;
    appearance: none;
    margin: 0;
    pointer-events: none;
    position: absolute;
    width: 100%;
    height: 100%;
    outline: none;
  }
}
.RelatedOutcomesFocusRing {
  input:focus-visible + & {
    height: calc(var(--state-checkbox-size) + #{rem($focus-ring-width * 2)});
    width: calc(var(--state-checkbox-size) + #{rem($focus-ring-width * 2)});
    border-radius: rem($state-checkbox-border-radius + $focus-ring-width);
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
    border: rem($focus-ring-width) solid var(--focus-ring-color);
  }
}
.RelatedOutcomesText {
  user-select: none;
  max-width: 42em;

  strong {
    margin: 0;
    transition: color $duration-fast $easing;

    @include max-width($bp-medium) {
      display: block;
      line-height: calc(
        #{$line-height-body-tighter} * var(--font-size-paragraph)
      );
    }
  }

  label:hover &,
  label:focus-within & {
    > strong {
      color: $color-600;
    }
  }
}

.MitigationHelper {
  color: $color-disabled;
  position: relative;
  margin-top: calc(var(--gutter) * 0.75);
}

.Buttons {
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;
  flex-wrap: wrap;
  display: flex;
  gap: calc(var(--gap) * 2);
  margin-top: var(--card-gutter);
  margin-bottom: auto;

  @include min-width($bp-small) {
    flex-direction: row;
  }
}
.ButtonsPrimary {
  display: flex;
  gap: var(--gap);
  width: 100%;

  @include min-width($bp-small) {
    width: auto;
  }
}
.ButtonsToggle {
  flex: 1;
}
.ButtonsToggleHideOnSmallScreens {
  @include max-width($bp-show-full-button-labels) {
    display: none;
  }
}
.ButtonsNext {
  margin-right: auto;
  transition: opacity $duration $duration $easing;

  &[aria-hidden="true"] {
    visibility: hidden;
    opacity: 0;
    transition: opacity $duration $easing, visibility 0ms $duration;
  }
}
