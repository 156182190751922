@import "src/scss/global";

.Error {
  color: $color-negative;
  font-weight: $fw-medium;
  font-size: var(--font-size-small);
  margin-top: var(--gap);
  line-height: $line-height-body-tighter;
  display: grid;
  grid-template-columns: auto 1fr;
  gap: var(--gap);
  width: 100%;
}

.IconWrapper {
  height: $line-height-body-tighter * 1em;
  width: auto;
  display: flex;

  svg {
    justify-self: center;
    align-self: center;
  }

  path {
    fill: currentColor;
  }
}
