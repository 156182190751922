@import "src/scss/global";

.List {
  padding-left: 0;
  list-style: none;
  margin-top: var(--gap);

  > li {
    padding-left: $list-item-padding;

    + li {
      margin-top: $text-relative-space;
    }

    &::before {
      content: "•";
      display: inline-block;
      width: $list-item-padding;
      margin-left: $list-item-padding * -1;
      text-align: center;
      opacity: $text-semi-transparent;
    }
  }

  &-ol {
    counter-reset: ordered;

    > li {
      &::before {
        counter-increment: ordered;
        content: counter(ordered) ".";
        font-weight: $fw-medium;
        font-variant-numeric: tabular-nums;
        text-align: right;
        letter-spacing: $letter-spacing-tabular-numbers;
        padding-right: $list-ol-marker-padding;
      }
    }

    [data-ol-marker] {
      &::before {
        content: attr(data-ol-marker) ".";
      }
    }
  }
}
